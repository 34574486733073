@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Raleway&display=swap');

:root {
    --black: #000000; /* background */
    --blue: #1E90FF;  /* secondary background and sections */
    --grey: #F5F5F5; /* text */
    --supporting-purple: rgb(197, 143, 245); /* buttons and icons */
    --purple: #9b39f5; /* buttons and icons #8A2BE2*/
}

body {
    margin: 0;
    font-family: 'Montserrat', sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--black) !important;
    color: var(--grey) !important;
}

#about-me-row{
    flex-direction: row;
}

.skill-box  .text-center{
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 8px 1em;
}

.container-fluid{
    padding: 0 !important;
}

.fab,.fas{
    color: var(--blue);
    font-size: 20px;
}

header, footer{
    background-color: var(--black);
    color: var(--grey);
}

.btn{
    background-color: var(--black) !important;
    color: var(--grey)  !important;
    border-color: var(--purple) !important;
    border-radius: 4px  !important;
    padding: 15px 30px !important;
    margin-top: 2em !important;
    font-weight: 600  !important;
    font-size: 20px  !important;
    cursor: pointer !important;
    width: fit-content;
}

.section-col-l, .section-col-r{
    padding-left: 3em !important;
    padding-right: 3em !important;
    width: 100%;
}

h2{
    font-size: 25px !important;
    color: var(--purple) !important;
    margin-bottom: 1.5em !important;
    text-align: left !important;
}

h3{
    font-size: 21px !important;
    color: var(--grey) !important;
    margin-bottom: 1.5em !important;
    margin-top: 2em !important;
}

.section-heading-container{
    margin-bottom: 2em;
}

.section-heading {
    font-size: 2.2rem;
    color: var(--purple);
}

.section-heading::after {
    content: '';
    display: block;
    width: 50px;
    height: 2px;
    border-radius: 4px;
    background-color: var(--blue);
    margin: 20px auto 20px auto ;
}

/*.even-text{*/
/*    text-align: justify;*/
/*}*/

header{
    padding: 1rem 2rem;
    font-weight: 400 !important;
    font-size: 20px !important;
    margin-bottom: 1em;
}

.navbar a{
    color: var(--grey) !important;
}

#name{
    font-weight: 600;
    color: var(--supporting-purple);
}

#occupation{
    font-weight: 700;
    color: var(--purple);
}

#dev-img{
    height: auto;
    width: 100%;
}

.navbar-collapse {
    text-align: left !important;
}

.navbar-nav {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.navbar-toggler {
    border-color: var(--purple) !important;
}
.navbar-toggler-icon {
    filter: brightness(0) invert(1);
    color: var(--purple) !important;
}

.project-desc {
    text-align: left;
    margin-top: 1em;
}

.prj-container{
    margin-bottom: 5em;
}

footer{
    font-size: 17px;
    letter-spacing: 2px;
    background-image: linear-gradient(rgb(8 0 16 / 44%) 0%, rgba(45, 1, 76, 0.83) 95%);
    padding-bottom: 1em !important;
    padding-top: 2em;
    color: var(--grey) !important;
    font-weight: 400;
    margin-top: 6em;
}

.fa.fa-chevron-right{
    padding-right: 9px;
    font-size: 14px;
    font-weight: bolder;
    color: var(--blue);
}

.main-contianer{
    display: flex;
    flex-direction: column;
}

main{
    font-size: 20px;
}

.section{
    display: flex;
    flex-direction: row;
    padding-left: 10em;
    padding-right: 10em;
    margin-top: 6em;
    gap: 8em;
    margin-bottom: 2em;
}

.section-row-two-images{
    display: flex;
    flex-direction: row;
}
.section-column{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.heading-container{
    align-items: center;
    justify-content: center;
}

#name{
    font-size: 55px;
}

#occupation{
    font-size: 60px;
}

.subsection-container{
    margin-top: 0;
}

.skills-grid{
    display: flex;
    flex-wrap: wrap;
    gap: 1.5em;
}

.skill-box {
    flex: 1 0 auto;
    max-width: 30%;
    font-size: 18px;
}
.img-column{
    align-items: end;
    justify-content: flex-start;
}
.proj-img{
    width: 100%;
    height: auto;
    object-fit: contain;
}
.mobile-img{
    max-width: 295px;
}
.laptop-img{
    max-width: 650px;
}
#lang-heading{
    margin-top: 0 !important;
}
.prj-desc-paragraph{
    margin-bottom: 2em;
}
.web-dev-heading{
    margin-top: 3em;
}
.mob-dev-heading{
    margin-top: 1em;
}
.section-dev-heading{
    margin-bottom: 1.5em !important;
}
.prj-heading{
    margin-top: 1em !important;
}
h1{
    font-size: 2.6rem !important;
}
#img-col{
    display: inline-block;
}
.backgrnd-img{
    background-image:linear-gradient(to bottom,
    rgba(155, 57, 245, 0.03) 0%,
    rgba(27, 0, 50, 0.4) 25%,
    rgba(17, 1, 31, 0.93) 50%,
    rgba(27, 0, 50, 0.4) 75%,
    rgba(155, 57, 245, 0.03) 100%
    )
}


@media only screen and (max-width: 991px) {
    #img-col{
        display: none;
    }
    .section-row-two-images {
        flex-direction: column;
    }
    .img-column {
        align-items: center;
        justify-content: center;
    }
    header{
        border-bottom: 1px solid var(--purple);
        margin-bottom: 1.5em;
    }
    .prj-heading {
        margin-top: 2em !important;
    }
    .section{
        padding-left: 2em;
        padding-right: 2em;
        margin-top: 1em;
        gap: 0;
        margin-bottom: 2.5em;
    }
    #name{
        font-size: 1.3rem;
    }
    #occupation{
        font-size: 1.4rem;
    }
    #main-heading{
        text-align: center;
    }
    h1{
        font-size: 1.3rem !important;
    }
    h2{
        font-size: 1.2rem !important;
        margin-bottom: 1em !important;
    }
    h3{
        font-size: 1.1rem !important;
        margin-top: 1em;
        margin-bottom: 1em;
    }
    main{
        font-size: 1rem;
    }
    .btn{
        width: 100%;
        font-size: 1rem !important;
    }
    .section{
        flex-direction: column;
        margin-bottom: 1em;
    }
    footer{
        margin-top: 2em;
        font-size: 0.9rem;
        letter-spacing: 1px;
    }
    .section-dev-heading{
        margin-top: 1.5em;
        margin-bottom: 0 !important;
    }
    .skill-box{
        font-size: 1rem;
        max-width: 30%;
    }
}
@media (min-width: 420px) and (max-width: 991px) {

    .section-row-two-images {
        flex-direction: row;
    }
}
@media (min-width:700px) and (max-width: 991px) {

    .skill-box{
        max-width: 22%;
    }
    h1{
        font-size: 1.5rem !important;
    }
    h2{
        font-size: 1.4rem !important;
    }
    h3{
        font-size: 1.3rem !important;
    }
    #name{
        font-size: 1.6rem;
    }
    #occupation{
        font-size: 1.7rem;
    }
    main{
        font-size: 1.2rem;
    }
    .btn{
        width: 100%;
        font-size: 1rem !important;
    }
    .section {
        padding-left: 3em;
        padding-right: 3em;
        margin-bottom: 2em;
    }

}

@media (min-width:992px) and (max-width: 1400px) {

    .section{
        padding-left: 5em;
        padding-right: 5em;
        margin-top: 3em;
        gap: 5em;
    }
    .skill-box{
        max-width: 35%;
        font-size: 16px;
    }
    h1{
        font-size: 1.5rem !important;
    }
    h2{
        font-size: 1.4rem !important;
    }
    h3{
        font-size: 1.1rem !important;
    }
    #name{
        font-size: 1.6rem;
    }
    #occupation{
        font-size: 1.7rem;
    }
    main{
        font-size: 1rem;
    }
    .btn{
        width: 50%;
        font-size: 1rem !important;
    }
    .subsection-container{
        margin-top: 0 !important;
    }

}
@media (min-width:1200px) and (max-width: 1400px) {
    .section {
        padding-left: 4em;
        padding-right: 4em;
    }
    h1{
        font-size: 1.5rem !important;
    }
    h2{
        font-size: 1.4rem !important;
    }
    h3{
        font-size: 1.1rem !important;
    }
    #name{
        font-size: 1.9rem;
    }
    #occupation{
        font-size: 2rem;
    }
    main{
        font-size: 1.2rem;
    }
}
@media (min-width:1401px) and (max-width: 1802px){
    .section {
        padding-left: 7em;
        padding-right: 7em;
        margin-top: 4em;
        gap: 6em;
    }
    .subsection-container{
        margin-top: 0 !important;
    }
    h1{
        font-size: 1.5rem !important;
    }
    h2{
        font-size: 1.4rem !important;
    }
    h3{
        font-size: 1.1rem !important;
    }
    #name{
        font-size: 1.9rem;
    }
    #occupation{
        font-size: 2rem;
    }
    main{
        font-size: 1.2rem;
    }
}
@media (min-width:1600px) and (max-width: 1802px){
    h1{
        font-size: 1.6rem !important;
    }
    h2{
        font-size: 1.5rem !important;
    }
    h3{
        font-size: 1.4rem !important;
    }
    #name{
        font-size: 2.5rem;
    }
    #occupation{
        font-size: 3rem;
    }
    main{
        font-size: 1.3rem;
    }
}